<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2">
            {{ $t("superintendence-register.title")
            }}<b-spinner
                v-if="loading"
                class="ms-2"
                variant="primary"
                small
            ></b-spinner>
        </h6>
        <b-row class="mb-5">
            <b-card
                class="shadow-sm border-0 rounded-0 px-md-4"
                bg-variant="light"
                no-header
            >
                <b-card-text>
                    <b-row>
                        <b-col md="6" class="pe-md-5 pb-5">
                            <b-form-group>
                                <label class="form-title">{{
                                    $t("general.name")
                                }}</label>
                                <b-form-input
                                    v-model="registryForm.name"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title">{{
                                    $t("general.identification-type")
                                }}</label>
                                <select
                                    class="form-select form-select-sm rounded-0"
                                    v-model="registryForm.identificationType"
                                >
                                    <option
                                        v-for="option in identifications"
                                        :key="option.value"
                                        :value="option.value"
                                    >
                                        {{ $t(`general.${option.text}`) }}
                                    </option>
                                </select>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title">{{
                                    $t("general.identification")
                                }}</label>
                                <b-form-input
                                    v-model="registryForm.identification"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <b-row class="mt-4">
                                <b-col md="9" lg="6" xl="4">
                                    <label class="form-title">{{
                                        $t(
                                            "superintendence-register.cedula-image"
                                        )
                                    }}</label>
                                    <div class="file-input mt-1">
                                        <label
                                            for="image"
                                            class="btn main-btn btn-secondary"
                                            ><b-icon-file-earmark-arrow-up
                                                class="me-1"
                                            ></b-icon-file-earmark-arrow-up
                                            >{{
                                                $t("general.select-file")
                                            }}</label
                                        >
                                        <input
                                            id="image"
                                            type="file"
                                            @change="onInputChange"
                                        />
                                    </div>
                                </b-col>
                                <b-col class="file-name-container">
                                    <span
                                        v-if="registryForm.cedulaFileName"
                                        class="file-name"
                                        >{{ registryForm.cedulaFileName }}</span
                                    >
                                    <span v-else class="file-name">{{
                                        $t("general.no-file-selected")
                                    }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="6" class="ps-md-5">
                            <b-form-group>
                                <label
                                    class="form-title"
                                    label-for="name-input"
                                    >{{ $t("general.phone") }}</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.phone"
                                    size="sm"
                                    class="rounded-0"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label
                                    class="form-title"
                                    label-for="name-input"
                                    >{{ $t("general.email") }}</label
                                >
                                <b-form-input
                                    id="name-input"
                                    v-model="registryForm.email"
                                    size="sm"
                                    class="rounded-0"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col class="text-end">
                            <b-overlay
                                :show="sending"
                                rounded
                                opacity="0.7"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block"
                            >
                                <b-button
                                    class="main-btn rounded px-4"
                                    @click="registerSuperintendent"
                                    :disabled="!canSave"
                                    >{{ $t("general.save") }}</b-button
                                >
                            </b-overlay>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-row>
    </div>
</template>

<script>
import { identifications } from "@/config/enums";
import { RepositoryFactory } from "@/services/api";
import { isUserRegistered } from "@/services/users";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "superintendence-register",
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            identifications: identifications,
            invitation: {},
            sending: false,
            loading: false,
            registryForm: {
                name: "",
                identificationType: null,
                identification: "",
                email: "",
                phone: "",
                cedulaFileName: "",
                cedulaPhotoContent: null,
            },
        };
    },
    async mounted() {
        this.loading = true;

        let userRegistered = await isUserRegistered();
        if (userRegistered) {
            this.setModalInfo({
                title: this.$t("user-registered.title"),
                message: this.$t("user-registered.message"),
                subMessage: this.$t("user-registered.subMessage"),
                routeName: "dashboard",
            });
            this.openModal();
            this.loading = false;
            return;
        }

        let user = this.oidcUser;
        this.registryForm.name =
            `${user["given_name"]} ${user["family_name"]}`.trim();
        this.registryForm.email = user["email"];
        this.registryForm.phone = user["phone_number"];
        this.registryForm.identification = user["cedula"] ?? user["passport"];

        await this.checkValidInvitation();

        this.loading = false;
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcUser"]),
        canSave() {
            if (this.loading || this.sending) {
                return false;
            }
            let form = this.registryForm;
            let validFields =
                form.identification !== "" &&
                form.cedulaFileName !== "" &&
                form.phone !== "" &&
                form.identificationType !== null;
            return validFields;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async checkValidInvitation() {
            let response =
                await RepositoryFactory.superintendence.getInvitation({
                    id: this.id,
                });
            this.invitation = response.data;

            if (this.invitation.used) {
                this.setModalInfo({
                    title: "Invitación no valida",
                    message:
                        "La invitaicón ya ha sido utilizada por otro usuariuo.",
                    subMessage:
                        "Por favor, contacte con quien le envió esta invitación y solicite una nueva.",
                    routeName: "panama-digital",
                });
                this.openModal();
            } else if (!this.isValidEmail()) {
                this.setModalInfo({
                    title: "Usuario no valido",
                    message:
                        "El usuario con el que esta intentando usar esta invitación no es valido",
                    subMessage:
                        "Por favor, contacte con quien le envió esta invitación y solicite una nueva",
                    routeName: "panama-digital",
                });
                this.openModal();
            }
        },
        isValidEmail() {
            if (this.invitation.email === this.oidcUser.email) {
                return true;
            }

            let valid = this.oidcUser["email_verified_valid"] === "1";
            let email = this.oidcUser["email_verified"];
            if (valid && email === this.invitation.email) {
                this.registryForm.email = email;
                return true;
            }

            return false;
        },
        async registerSuperintendent() {
            this.sending = true;
            try {
                let superintendence = { ...this.registryForm };
                let request = {
                    invitationId: this.id,
                    superintendence: superintendence,
                };
                await RepositoryFactory.superintendence.add(request);
                this.setSuccesInfo();
            } catch (error) {
                window.console.log(error);
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: this.$t("register.success.title"),
                message: this.$t("register.success.message"),
                subMessage: this.$t("register.success.subMessage"),
                routeName: "logout",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: this.$t("register.error.title"),
                message: this.$t("register.error.message"),
                subMessage: this.$t("register.error.subMessage"),
            });
        },
        onInputChange(e) {
            let image = e.target.files[0];
            this.registryForm.cedulaFileName = image.name;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                let content = e.target.result;
                this.registryForm.cedulaPhotoContent = content
                    .split(";base64,")
                    .pop();
            };
        },
    },
};
</script>